@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap);
body {
	font-family: 'Roboto', sans-serif;
}
.reset-link-style {
	color: inherit;
	text-decoration: inherit;
}

.reset-link-style:hover,
.reset-link-style:focus,
.reset-link-style:active {
	color: inherit;
	text-decoration: inherit;
}

img {
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
}

.modal-content {
	border: none;
	border-radius: 0;
	background-color: #f0ede8;
}

.modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: 30px;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
	.modal-body {
		padding: 30px 15px;
	}
}

.bg-home {
	background-color: #fff;
}

.product-home {
	padding: 0 0 120px 0;
}

.bg-products {
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-image: linear-gradient(rgba(0, 0, 0, 0.2), #16070172), url(/static/media/kafa3-min.8d8e07d5.jpg);
	height: calc(100vh - 50px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.col-product-home {
	height: calc(100vh - 50px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.col-product-home:last-of-type {
	border-right: none;
}

.title-product-home {
	font-size: 50px;
	color: rgba(255, 255, 255, 0.842);
	font-weight: 500;
	font-family: 'Playfair Display', serif;
	margin-bottom: -50px;
	cursor: pointer;
	transition: all .4s ease;
	text-align: center;
}
.title-product-home:hover {
	color: rgba(255, 255, 255, 0.5);
}

.link-product-home {
	font-size: 18px;
	color: rgba(255, 255, 255, 0.842);
	font-weight: 400;
	margin-bottom: 20px;
	cursor: pointer;
	text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.185);
}

.home-layout {
	padding-top: 50px;
}

.image-card-home {
	height: auto;
	width: 100%;
}

.card-home {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 60px;
}

.video-card-home {
	display: block;
	height: auto;
	width: 100%;
}

.position-card-home {
	position: relative;
}

.title-card-home {
	font-size: 50px;
	color: #302b31;
	font-weight: 700;
	font-family: 'Playfair Display', serif;
}

.subtitle-card-home {
	font-size: 15px;
	color: #c59361;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1.3px;
}

.text-card-home {
	font-size: 15px;
	color: #868686;
	font-weight: 400;
	margin-bottom: 30px;
	max-width: 900px;
}

.button-card-home {
	height: 55px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	min-width: -webkit-fit-content;
	min-width: -moz-fit-content;
	min-width: fit-content;
	background-color: #895639;
	padding: 0 40px;
	font-size: 15px;
	color: #fff;
	font-weight: 400;
	text-transform: uppercase;
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
	transition: all .4s;
}

.button-card-home:hover,
.button-card-home:focus,
.button-card-home:active {
	background-color: #9b6343;
	color: #fff;
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
}

/* modal */

.close-icon-modal-home {
	height: 20px;
	max-width: 100%;
	cursor: pointer;
	fill: #302b31;
	transition: .4s all;
	position: absolute;
	right: 15px;
	top: 15px;
}
.close-icon-modal-home:hover {
	fill: #302b317e;
}
.subtitle-modal-card-home {
	font-size: 15px;
	color: #c59361;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1.3px;
	margin-bottom: 0;
}
.title-modal-home {
	font-size: 50px;
	color: #302b31;
	font-weight: 700;
	font-family: 'Playfair Display', serif;
	margin-bottom: 30px;
}

.input-modal-home {
	background-color: #fff;
	height: 50px;
	width: 100%;
	border: none;
	padding: 0 15px;
	font-size: 15px;
	font-weight: 400;
	color: #302b31;
	margin-bottom: 15px;
	transition: all .4s;
	box-shadow: none;
	outline: none;
	border: none;
}
.input-modal-home::-webkit-input-placeholder {
	color: #868686;
	font-size: 15px;
}
.input-modal-home:-ms-input-placeholder {
	color: #868686;
	font-size: 15px;
}
.input-modal-home::-ms-input-placeholder {
	color: #868686;
	font-size: 15px;
}
.input-modal-home::placeholder {
	color: #868686;
	font-size: 15px;
}

.text-form-modal-home {
	font-size: 15px;
	color: #302b31;
	font-weight: 400;
	margin-bottom: 0;
}

.button-modal-card-home {
	height: 55px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	min-width: -webkit-fit-content;
	min-width: -moz-fit-content;
	min-width: fit-content;
	background-color: #895639;
	padding: 0 40px;
	font-size: 15px;
	color: #fff;
	font-weight: 400;
	text-transform: uppercase;
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
	transition: all .4s;
	margin-bottom: 15px;
	margin-top: 15px;
}

.button-modal-card-home:hover,
.button-modal-card-home:focus,
.button-modal-card-home:active {
	background-color: #9b6343;
	color: #fff;
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
}
/* media */

@media screen and (min-width: 0px) and (max-width: 992px) {
	.card-home {
		padding: 35px 15px 40px 15px;
	}
	.title-card-home {
		font-size: 35px;
	}
	.subtitle-card-home {
		font-size: 15px;
	}
	.text-card-home {
		font-size: 14px;
		margin-bottom: 20px;
		max-width: unset;
	}
	.button-card-home {
		height: 50px;
		padding: 0 30px;
		font-size: 14px;
	}
	/* sm only */
	.column-reverse-card-home {
		flex-direction: column-reverse;
	}

	/* modal */

	.close-icon-modal-home {
		height: 20px;
		right: 15px;
		top: 15px;
	}

	.subtitle-modal-card-home {
		font-size: 15px;
		margin-bottom: 5px;
	}

	.title-modal-home {
		font-size: 30px;
		margin-bottom: 20px;
	}

	.input-modal-home {
		font-size: 14px;
		margin-bottom: 15px;
	}

	.input-modal-home::-webkit-input-placeholder {
		font-size: 14px;
	}

	.input-modal-home:-ms-input-placeholder {
		font-size: 14px;
	}

	.input-modal-home::-ms-input-placeholder {
		font-size: 14px;
	}

	.input-modal-home::placeholder {
		font-size: 14px;
	}

	.text-form-modal-home {
		font-size: 14px;
	}

	.button-modal-card-home {
		height: 50px;
		padding: 0 30px;
		font-size: 14px;
		margin-bottom: 10px;
		margin-top: 5px;
	}

	.product-home {
		padding: 60px 0 60px 0;
	}

	.bg-products {
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		background-image: linear-gradient(rgba(0, 0, 0, 0.1), #1607014f), url(/static/media/kafa3-min.8d8e07d5.jpg);
		height: unset;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
	}

	.scroll-product-home {
		/* flex-wrap: unset;
		overflow-x: scroll; */
	}

	.col-product-home {
		height: unset;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-right: none;
		border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	}

	.col-product-home:last-of-type {
		border-right: none;
	}

	.title-product-home {
		font-size: 35px;
		margin: 0;
	}
	.title-product-home:hover {
		color: rgba(255, 255, 255, 0.842);
		margin-bottom: 0;
	}

	.link-product-home {
		display: none;
	}
}

@media screen and (min-width: 993px) and (max-width: 1681px) {
	.title-product-home {
		font-size: 38px;
	}
}

.intro-image-tradicionalna {
	width: 100%;
	height: auto;
}

.text-intro-tradicionalna {
	font-size: 15px;
	color: #868686;
	font-weight: 400;
	margin-bottom: 40px;
}

.beans-intro-image-tradicionalna {
	height: 120px;
	max-width: 100%;
	margin-bottom: 40px;
}

.shadow-intro-image-tradicionalna {
	width: 100%;
	height: auto;
	margin-bottom: 160px;
}

/* tradicionalna */

.section-tradicionalna {
	padding: 0 0 30px 0;
}

.premium-bg {
	/* background-color: #cdaf8f; */
	background-image: url(/static/media/background-1.45bf8274.png), -webkit-linear-gradient(-171deg, #e6c8a8, #9c8366);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: right;
}

.rio-bg {
	/* background-color: #aa937b; */
	background-image: -webkit-linear-gradient(-171deg, #e6c8a8, #9c8366);
}

.col-align-right-section-tradicionalna {
	text-align: right;
}

.col-align-left-section-tradicionalna {
	text-align: left;
}

.align-content-section-tradicionalna {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.image-section-tradicionalna {
	max-height: 700px;
	max-width: 100%;
	margin-top: -120px;
}

.title-section-tradicionalna {
	font-size: 45px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 700;
	font-family: 'Playfair Display', serif;
	margin-bottom: 25px;
	padding-top: 30px;
}

.text-section-tradicionalna {
	font-size: 15px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 25px;
}

.button-section-tradicionalna {
	height: 55px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	min-width: -webkit-fit-content;
	min-width: -moz-fit-content;
	min-width: fit-content;
	padding: 0 40px;
	font-size: 15px;
	font-weight: 400;
	text-transform: uppercase;
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
	transition: all .4s;
}

.button-section-tradicionalna:hover,
.button-section-tradicionalna:focus,
.button-section-tradicionalna:active {
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
}

.premium-button {
	background-color: #452b1e;
	color: #fff;
}

.premium-button:hover,
.premium-button:focus,
.premium-button:active {
	background-color: #603d2c;
	color: #fff;
}

.organic-button {
	background-color: #000;
	color: #fff;
}

.organic-button:hover,
.organic-button:focus,
.organic-button:active {
	background-color: #000;
	color: #fff;
}

/* tradicionalna */

.card-tradicionalna {
	padding: 0 60px 30px 0;
	margin: 180px 0;
}

.custom-padding-left {
	padding-left: 60px;
}
.last-card-tradicionalna {
	margin-top: 0;
	margin-bottom: 120px;
}

.organic-bg {
	background-image: -webkit-linear-gradient(-171deg, #83944e, #bfce82);
}

.miris-sa-bg {
	background-image: -webkit-linear-gradient(121deg, #1e3f85 0%, #9bb0da 99%);
}

.miris-bez-bg {
	background-image: -webkit-linear-gradient(121deg, rgb(19, 19, 19) 0%, rgb(70, 69, 69) 99%);
}

.col-align-right-card-tradicionalna {
	text-align: right;
}

.col-align-left-card-tradicionalna {
	text-align: left;
}

.align-content-card-tradicionalna {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.image-card-tradicionalna {
	max-height: 700px;
	max-width: 100%;
	margin-top: -120px;
}
.custom-image-size-card-tradicionalna {
	margin-top: -120px;
	max-height: 550px;
	margin-bottom: 30px;
}

.title-card-tradicionalna {
	font-size: 45px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 700;
	font-family: 'Playfair Display', serif;
	margin-bottom: 25px;
	padding-top: 30px;
}

.text-card-tradicionalna {
	font-size: 15px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 25px;
}

.button-card-tradicionalna {
	height: 55px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	min-width: -webkit-fit-content;
	min-width: -moz-fit-content;
	min-width: fit-content;
	padding: 0 40px;
	font-size: 15px;
	font-weight: 400;
	text-transform: uppercase;
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
	transition: all .4s;
}

.button-card-tradicionalna:hover,
.button-card-tradicionalna:focus,
.button-card-tradicionalna:active {
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
}
.miris-sa-button {
	color: #fff;
	background-color: #cdaf8f;
}
.miris-sa-button:hover,
.miris-sa-button:focus,
.miris-sa-button:active {
	color: #fff;
	background-color: #a1886d;
}

.miris-bez-button {
	color: #fff;
	background-color: #cdaf8f;
}

.miris-bez-button:hover,
.miris-bez-button:focus,
.miris-bez-button:active {
	color: #fff;
	background-color: #a1886d;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
	.intro-image-tradicionalna {
		height: 350px;
		width: 100%;
		object-fit: cover;
	}

	.text-intro-tradicionalna {
		margin-top: 20px;
		font-size: 14px;
		margin-bottom: 30px;
	}

	.beans-intro-image-tradicionalna {
		height: 80px;
		margin-bottom: 30px;
	}

	.shadow-intro-image-tradicionalna {
		margin-bottom: 110px;
	}

	/* tradicionalna */

	.section-tradicionalna {
		padding: 0 0 40px 0;
	}

	.premium-bg {
		background-position: top right;
		background-size: 350px, cover;
	}

	.col-align-right-section-tradicionalna {
		text-align: center;
	}

	.col-align-left-section-tradicionalna {
		text-align: center;
	}

	.align-content-section-tradicionalna {
		align-items: center;
	}

	.image-section-tradicionalna {
		max-height: 380px;
		margin-top: -80px;
	}

	.title-section-tradicionalna {
		font-size: 30px;
		margin-bottom: 15px;
		padding-top: 15px;
	}

	.text-section-tradicionalna {
		font-size: 14px;
		margin-bottom: 20px;
	}

	.button-section-tradicionalna {
		height: 50px;
		padding: 0 30px;
		font-size: 14px;
	}

	/* tradicionalna */

	.card-tradicionalna {
		padding: 0 15px 40px 15px;
		margin: 130px 0;
	}

	.custom-padding-left {
		padding-left: 15px;
	}

	.last-card-tradicionalna {
		margin-top: 0;
		margin-bottom: 80px;
	}

	.col-align-right-card-tradicionalna {
		text-align: center;
	}

	.col-align-left-card-tradicionalna {
		text-align: center;
	}

	.align-content-card-tradicionalna {
		align-items: center;
	}

	.image-card-tradicionalna {
		max-height: 350px;
		margin-top: -80px;
	}
	.custom-image-size-card-tradicionalna {
		margin-top: -80px;
		max-height: 350px;
		margin-bottom: 0;
	}

	.title-card-tradicionalna {
		font-size: 30px;
		margin-bottom: 15px;
		padding-top: 25px;
	}

	.text-card-tradicionalna {
		font-size: 14px;
		margin-bottom: 20px;
	}

	.button-card-tradicionalna {
		height: 50px;
		padding: 0 30px;
		font-size: 14px;
	}
	.column-reverse-section-tradicionalna {
		flex-direction: column-reverse;
	}
}

.intro-image {
	height: 100%;
	width: 100%;
}
.text-intro {
	font-size: 15px;
	color: #868686;
	font-weight: 400;
	margin-bottom: 40px;
}
.intro-image-beans {
	height: 120px;
	max-width: 100%;
	margin-bottom: 40px;
}
.intro-image-shadow {
	width: 100%;
	height: auto;
	margin-bottom: 160px;
}

.card-espresso {
	padding-bottom: 30px;
	margin-bottom: 190px;
}
.padding-right-card-espresso {
	padding-right: 60px;
}
.padding-left-card-espresso {
	padding-left: 60px;
}

.background-strong-card-espresso {
	background-image: -webkit-linear-gradient(
		22deg,
		rgb(233, 198, 101) 0%,
		rgb(192, 158, 78) 80%,
		rgb(151, 118, 55) 100%
	);
}
.background-organic-card-espresso {
	background-image: -webkit-linear-gradient(-171deg, #ded5cb 0%, #a19180 100%);
}
.background-corposo-card-espresso {
	background-image: -webkit-linear-gradient(-171deg, rgb(207, 207, 207) 0%, rgb(104, 104, 104) 100%);
}

.background-forte-card-espresso {
	background-image: -webkit-linear-gradient(22deg, rgb(223, 108, 102) 0%, rgb(200, 59, 53) 72%, rgb(177, 9, 3) 100%);
}

.background-venezia-card-espresso {
	background-image: -webkit-linear-gradient(-171deg, rgb(207, 207, 207) 0%, rgb(104, 104, 104) 100%);
}

.background-firenze-card-espresso {
	background-image: -webkit-linear-gradient(22deg, rgb(194, 127, 133) 0%, rgb(130, 76, 81) 72%, rgb(65, 25, 29) 100%);
}

.image-card-espresso {
	max-height: 600px;
	max-width: 100%;
	margin-top: -120px;
}

.col-align-right-card-espresso {
	text-align: right;
}
.col-align-left-card-espresso {
	text-align: left;
}

.align-content-right-card-espresso {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.align-content-left-card-espresso {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.title-card-espresso {
	font-size: 45px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 700;
	font-family: 'Playfair Display', serif;
	margin-bottom: 25px;
	padding-top: 30px;
}

.text-card-espresso {
	font-size: 15px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 25px;
}

.sub-text-card-espresso {
	font-size: 16px;
	color: #fff;
	font-weight: 700;
	margin-bottom: 25px;
}

.button-card-espresso {
	height: 55px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	min-width: -webkit-fit-content;
	min-width: -moz-fit-content;
	min-width: fit-content;
	background-color: #000;
	padding: 0 40px;
	font-size: 15px;
	color: #fff;
	font-weight: 400;
	text-transform: uppercase;
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
	transition: all .4s;
}

.button-card-espresso:hover,
.button-card-espresso:focus,
.button-card-espresso:active {
	background-color: rgb(28, 28, 28);
	color: #fff;
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
}
.last-card-espresso {
	margin-bottom: 100px;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
	.intro-image {
		height: 350px;
		width: 100%;
		object-fit: cover;
	}

	.text-intro {
		margin-top: 20px;
		font-size: 14px;
		margin-bottom: 30px;
	}

	.intro-image-beans {
		height: 80px;
		margin-bottom: 30px;
	}

	.intro-image-shadow {
		margin-bottom: 105px;
	}

	.card-espresso {
		padding: 0 15px 30px 15px;
		margin-bottom: 120px;
		text-align: center;
	}

	.image-card-espresso {
		max-height: 320px;
		margin-top: -70px;
	}

	.col-align-right-card-espresso {
		text-align: center;
	}

	.col-align-left-card-espresso {
		text-align: center;
	}

	.align-content-right-card-espresso {
		align-items: center;
	}

	.align-content-left-card-espresso {
		align-items: center;
	}

	.title-card-espresso {
		font-size: 30px;
		margin-bottom: 15px;
		padding-top: 25px;
	}

	.text-card-espresso {
		font-size: 14px;
		margin-bottom: 20px;
	}

	.sub-text-card-espresso {
		font-size: 16px;
		margin-bottom: 30px;
	}

	.button-card-home {
		height: 50px;
		padding: 0 30px;
		font-size: 14px;
	}
	.last-card-espresso {
		margin-bottom: 80px;
	}
	/* sm only */
	.column-reverse-card-espresso {
		flex-direction: column-reverse;
	}
}

.intro-image-instant {
	height: 100%;
	width: 100%;
}

.text-intro-instant {
	font-size: 15px;
	color: #868686;
	font-weight: 400;
	margin-bottom: 40px;
}

.beans-intro-image-instant {
	height: 120px;
	max-width: 100%;
	margin-bottom: 40px;
}

.shadow-intro-image-instant {
	width: 100%;
	height: auto;
	margin-bottom: 130px;
}

.card-instant {
	padding: 0 60px 60px 60px;
	margin-bottom: 180px;
}

.bg-2in1-red {
	background-image: -webkit-linear-gradient(148deg, rgb(181, 29, 30) 0%, rgb(214, 50, 44) 80%, rgb(247, 70, 57) 100%);
}

.bg-3in1-gold {
	background-image: -webkit-linear-gradient(
		22deg,
		rgb(233, 198, 101) 0%,
		rgb(192, 158, 78) 80%,
		rgb(151, 118, 55) 100%
	);
}
.bg-3in1-gray {
	background-image: -webkit-linear-gradient(
		37deg,
		rgb(174, 174, 174) 0%,
		rgb(208, 208, 208) 80%,
		rgb(174, 174, 174) 100%
	);
}
.bg-capucino {
	background-image: -webkit-linear-gradient(
		37deg,
		rgb(72, 123, 170) 0%,
		rgb(107, 151, 191) 80%,
		rgb(142, 178, 212) 100%
	);
}

.bg-soya-3in1-green {
	background-image: -webkit-linear-gradient(
		-154deg,
		rgb(227, 248, 154) 0%,
		rgb(190, 224, 83) 80%,
		rgb(153, 200, 11) 100%
	);
}

.bg-soya-2in1-blue {
	background-image: -webkit-linear-gradient(
		37deg,
		rgb(115, 200, 244) 0%,
		rgb(136, 207, 244) 80%,
		rgb(157, 214, 244) 100%
	);
}

.bg-soya-3in1-vanilla-yellow {
	background-image: -webkit-linear-gradient(
		37deg,
		rgb(222, 171, 0) 0%,
		rgb(227, 188, 53) 80%,
		rgb(232, 205, 105) 100%
	);
}

.align-content-right-card-instant {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}

.title-card-instant {
	font-size: 45px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 700;
	font-family: 'Playfair Display', serif;
	margin-bottom: 25px;
	padding-top: 60px;
}

.text-card-instant {
	font-size: 15px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 25px;
}

.image-card-instant {
	margin-top: -130px;
	max-width: 100%;
	max-height: 550px;
}

.button-card-instant {
	height: 55px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	min-width: -webkit-fit-content;
	min-width: -moz-fit-content;
	min-width: fit-content;
	background-color: #000;
	padding: 0 40px;
	font-size: 15px;
	color: #fff;
	font-weight: 400;
	text-transform: uppercase;
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
	transition: all .4s;
}

.button-card-instant:hover,
.button-card-instant:focus,
.button-card-instant:active {
	background-color: rgb(28, 28, 28);
	color: #fff;
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
}

.col-align-left-card-instant {
	text-align: left;
}

.col-align-right-card-instant {
	text-align: right;
}

.last-card-instant {
	margin-bottom: 120px;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
	.intro-image-instant {
		height: 350px;
		width: 100%;
		object-fit: cover;
	}

	.text-intro-instant {
		margin-top: 20px;
		font-size: 14px;
		margin-bottom: 30px;
	}

	.beans-intro-image-instant {
		height: 80px;
		margin-bottom: 30px;
	}

	.shadow-intro-image-instant {
		margin-bottom: 110px;
	}

	.card-instant {
		padding: 0 15px 40px 15px;
		margin-bottom: 120px;
	}

	.align-content-right-card-instant {
		align-items: center;
	}

	.title-card-instant {
		font-size: 30px;
		margin-bottom: 15px;
		padding-top: 25px;
	}

	.text-card-instant {
		font-size: 14px;
		margin-bottom: 20px;
	}

	.image-card-instant {
		margin-top: -80px;
		max-width: 100%;
		max-height: 250px;
	}

	.button-card-instant {
		height: 50px;
		padding: 0 30px;
		font-size: 14px;
	}

	.col-align-left-card-instant {
		text-align: center;
	}

	.col-align-right-card-instant {
		text-align: center;
	}
	.last-card-instant {
		margin-bottom: 80px;
	}

	/* sm only */
	.column-reverse-card-instant {
		flex-direction: column-reverse;
	}
}

.intro-image-capsule {
	height: 100%;
	width: 100%;
}
.text-intro-capsule {
	font-size: 15px;
	color: #868686;
	font-weight: 400;
	margin-bottom: 40px;
}

.intro-image-single-capsule {
	height: 120px;
	max-width: 100%;
	margin-bottom: 40px;
}
.shadow-image-shadow-capsule {
	width: 100%;
	height: auto;
	margin-bottom: 160px;
}

.aparat {
	background-image: -webkit-linear-gradient(52deg, rgb(255, 123, 117) 0%, rgb(216, 66, 60) 72%, rgb(177, 9, 3) 100%);
	padding-bottom: 60px;
}

.image-aparat-capsule {
	max-height: 500px;
	max-width: 100%;
	margin-top: -120px;
}
.align-content-aparat-capsule {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.col-align-aparat-capsule {
	text-align: right;
}
.title-aparat-capsule {
	font-size: 45px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 700;
	font-family: 'Playfair Display', serif;
	margin-bottom: 25px;
	padding-top: 60px;
}

.text-aparat-capsule {
	font-size: 15px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 25px;
}

.button-aparat-capsule {
	height: 55px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	min-width: -webkit-fit-content;
	min-width: -moz-fit-content;
	min-width: fit-content;
	background-color: #000;
	padding: 0 40px;
	font-size: 15px;
	color: #fff;
	font-weight: 400;
	text-transform: uppercase;
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
	transition: all .4s;
	margin-top: 5px;
}

.button-aparat-capsule:hover,
.button-aparat-capsule:focus,
.button-aparat-capsule:active {
	background-color: rgb(28, 28, 28);
	color: #fff;
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
}

/* products cards */
.card-capsule {
	margin-top: 180px;
	background-image: -webkit-linear-gradient(121deg, rgb(19, 19, 19) 0%, rgb(49, 49, 49) 99%);
	padding: 0 60px 60px 60px;
}

.col-align-right-card-capsule {
	text-align: right;
}

.col-align-left-card-capsule {
	text-align: left;
}

.align-content-left-card-capsule {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.align-content-right-card-capsule {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-end;
}

.image-product-card-capsule {
	max-height: 550px;
	max-width: 100%;
	margin-top: -108px;
}
.title-card-capsule {
	font-size: 45px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 700;
	font-family: 'Playfair Display', serif;
	margin-bottom: 25px;
	padding-top: 60px;
}
.text-card-capsule {
	font-size: 15px;
	color: #fff;
	font-weight: 400;
	margin-bottom: 25px;
}

.image-intensity-card-capsule {
	height: 80px;
	margin-bottom: 30px;
}

.button-card-capsule {
	height: 55px;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	min-width: -webkit-fit-content;
	min-width: -moz-fit-content;
	min-width: fit-content;
	/* background-color: #000;
	color: #fff; */
	padding: 0 40px;
	font-size: 15px;
	
	font-weight: 400;
	text-transform: uppercase;
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
	transition: all .4s;
	margin-top: 5px;
}

.button-card-capsule:hover,
.button-card-capsule:focus,
.button-card-capsule:active {
	/* background-color: rgb(28, 28, 28);
	color: #fff; */
	border: none;
	outline: none;
	border-radius: 0;
	box-shadow: none;
}

.last-card-capsule {
	margin-bottom: 120px;
}

.btn-strong{
	color: #fff;
	background-color: #c0a671;
}
.btn-strong:hover,
.btn-strong:focus,
.btn-strong:active{
	background-color: #c0a671;
}


.btn-corposo{
	color: #fff;
	background-color: #92979a;
}
.btn-corposo:hover,
.btn-corposo:focus,
.btn-corposo:active{
	background-color: #92979a;
}


.btn-forte{
	color: #fff;
	background-color: #ee2e44;
}
.btn-forte:hover,
.btn-forte:focus,
.btn-forte:active{
	background-color: #ee2e44;
}

.btn-decaffeinato{
	color: #fff;
	background-color: #0fb5ee;
}
.btn-decaffeinato:hover,
.btn-decaffeinato:focus,
.btn-decaffeinato:active{
	background-color: #0fb5ee;
}

.btn-venezia{
	color: #fff;
	background-color: #942a4b;
}
.btn-venezia:hover,
.btn-venezia:focus,
.btn-venezia:active{
	background-color: #942a4b;
}

.btn-firenze{
	color: #fff;
	background-color: #6d4f50;
}
.btn-firenze:hover,
.btn-firenze:focus,
.btn-firenze:active{
	background-color: #6d4f50;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
	.intro-image-capsule {
		height: 350px;
		width: 100%;
		object-fit: cover;
	}
	.text-intro-capsule {
		margin-top: 20px;
		font-size: 14px;
		margin-bottom: 30px;
	}

	.intro-image-single-capsule {
		height: 80px;
		margin-bottom: 30px;
	}
	.shadow-image-shadow-capsule {
		margin-bottom: 120px;
	}

	.aparat {
		padding-bottom: 40px;
	}

	.image-aparat-capsule {
		max-height: 320px;
		margin-top: -90px;
	}
	/* only sm */
	.col-align-left-aparat-capsule {
		text-align: center;
	}

	.align-content-aparat-capsule {
		align-items: center;
	}

	.col-align-aparat-capsule {
		text-align: center;
	}

	.title-aparat-capsule {
		font-size: 30px;
		margin-bottom: 15px;
		padding-top: 30px;
	}

	.text-aparat-capsule {
		font-size: 14px;
		margin-bottom: 20px;
	}

	.button-aparat-capsule {
		height: 50px;
		padding: 0 30px;
		font-size: 14px;
	}

	/* products cards */

	.card-capsule {
		margin-top: 100px;
		padding: 0 15px 40px 15px;
	}

	.col-align-right-card-capsule {
		text-align: center;
	}

	.col-align-left-card-capsule {
		text-align: center;
	}

	.align-content-left-card-capsule {
		align-items: center;
	}

	.align-content-right-card-capsule {
		align-items: center;
	}

	.image-product-card-capsule {
		max-height: 320px;
		margin-top: -63px;
	}

	.title-card-capsule {
		font-size: 30px;
		margin-bottom: 15px;
		padding-top: 30px;
	}

	.text-card-capsule {
		font-size: 14px;
		margin-bottom: 25px;
	}

	.image-intensity-card-capsule {
		height: 70px;
		margin-bottom: 25px;
	}

	.button-card-capsule {
		height: 50px;
		padding: 0 30px;
		font-size: 14px;
	}

	.last-card-capsule {
		margin-bottom: 80px;
	}

	/* only sm */
	.column-reverse-card-capsule {
		flex-direction: column-reverse;
	}
}

.custom-margin-bottom{
    margin-bottom: 15px;
}
.icon-contact{
    height: 35px;
    margin-right: 10px;
}

.custom-margin-bottom-contact{
    margin-bottom: 25px;
}

.span-constact{
    font-size: 15px;
    color: #302b31;
    font-weight: 400;
}
.title-contact{
    margin-bottom: 30px;
}

.image-single-news {
	max-width: 100%;
	height: auto;
}
.align-content-single-news {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 60px;
	border-bottom: 1px solid #f0ede8;
}
.title-single-news {
	font-size: 50px;
	color: #302b31;
	font-weight: 700;
	font-family: 'Playfair Display', serif;
}
.subtitle-single-news {
	font-size: 15px;
	color: #c59361;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 1.3px;
}
.text-single-news {
	font-size: 18px;
	color: #868686;
	font-weight: 400;
}

.content-single-news {
	margin: 80px 0;
}
.bold-news {
	font-weight: 700;
	color: #585858;
}
.underline {
	-webkit-text-decoration-color: #a7a6a6;
	        text-decoration-color: #a7a6a6;
	text-decoration: underline;
}
.italic-news {
	font-style: italic;
	font-weight: 700;
	color: #585858;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
	.image-single-news {
		margin-bottom: 30px;
	}
	.align-content-single-news {
		padding: 0 15px;
		border-bottom: none;
	}
	.title-single-news {
		font-size: 35px;
	}
	.subtitle-single-news {
		font-size: 15px;
		color: #c59361;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: 1.3px;
	}

	.text-single-news {
		font-size: 16px;
	}

	.content-single-news {
		margin: 15px 0 80px 0;
	}
}

.layout {
	padding-top: 50px;
}

.navigation {
	height: 50px;
	width: 100%;
	background-color: #000;
	position: fixed;
	display: flex;
	flex-direction: column;
	justify-content: center;
	z-index: 999;
}

.logo {
	height: 35px;
}

.icon-hamburger {
	height: 16px;
	cursor: pointer;
	transition: .4s all;
}
.icon-hamburger:hover {
	fill: rgba(255, 255, 255, 0.5);
}

.items-position {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

/* menu hamburger */
.menu {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: #000;
	z-index: 9999;
	transform: translateY(-100%);
	transition: all 1s ease;

	background-image: url("/static/media/omcafe outline logo.e8cd2629.svg");
	background-size: 35%;
	background-position: right -5% bottom -35%;
	background-repeat: no-repeat;

	padding-top: 30px;
}

.menu--active {
	transform: translateY(0) !important;
}

.logo-menu {
	height: 50px;
	max-width: 100%;
}
.text-logo-menu {
	color: #fff;
	height: 18px;
	margin-left: 30px;
	border-left: 1px solid rgba(255, 255, 255, 0.2);
	padding-left: 30px;
}

.close-menu {
	height: 20px;
	max-width: 100%;
	cursor: pointer;
	transition: .4s all;
}
.close-menu:hover {
	fill: rgba(255, 255, 255, 0.5);
}

.col-logo-menu {
	text-align: left;
}
.col-close-menu {
	text-align: right;
}
.link-menu {
	font-size: 45px;
	color: #fff;
	font-weight: 500;
	font-family: 'Playfair Display', serif;
	margin-bottom: 0px;
	transition: all .5s ease;
}
.link-menu:hover {
	color: rgba(255, 255, 255, 0.5);
}
.position-menu {
	margin-top: 30px;
	padding-top: 30px;
	border-top: 1px solid rgba(255, 255, 255, 0.2);
}
.title-menu {
	font-size: 15px;
	color: #c59361;
	font-weight: 400;
	text-transform: uppercase;
	letter-spacing: 2px;
}
.icon-language{
	height: 20px;
	margin-right: 10px;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
	.menu {
		background-image: url("/static/media/omcafe outline logo.e8cd2629.svg");
		background-size: 250px;
		background-position: right -50px bottom -50px;
		padding-top: 15px;
	}
	.logo-menu {
		height: 35px;
	}

	.text-logo-menu {
		height: 16px;
		margin-left: 15px;
		padding-left: 15px;
		display: none;
	}

	.close-menu {
		height: 20px;
	}
	.link-menu {
		font-size: 24px;
		margin-bottom: 5px;
	}

	.position-menu {
		margin-top: 15px;
		padding-top: 30px;
	}

	.title-menu {
		font-size: 14px;
		letter-spacing: 2px;
	}
	.language {
		margin-top: 30px;
	}
}

.footer {
	background-color: #452b1e;
	/* background-color: #372e26; */
	padding: 120px 0;
}
.logo-footer {
	height: 100px;
}

.title-footer {
	font-size: 20px;
	color: #fff;
	font-weight: 400;
	text-transform: uppercase;
	margin-bottom: 30px;
	letter-spacing: 1.1px;
}

.link-footer {
	font-size: 15px;
	color: #a38677;
	font-weight: 400;
	margin-bottom: 15px;
	transition: all .3s;
	cursor: pointer;
}

.link-footer:hover {
	color: #ddbbaa;
}

.copyright {
	padding: 20px 0;
	background-color: #24170f;
}

.text-copyright {
	font-size: 14px;
	color: #a38677;
	font-weight: 400;
	transition: all .3s;
}

.made-by-copyright {
	color: #ddbbaa;
}

.col-copyright-left {
	text-align: left;
}

.col-copyright-right {
	text-align: right;
}
.icon-footer {
	height: 28px;
	margin-right: 10px;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
	.footer {
		padding: 60px 0;
	}

	.footer-group {
		margin-bottom: 45px;
	}

	.logo-footer {
		height: 80px;
		margin-bottom: 50px;
	}

	.title-footer {
		font-size: 20px;
		margin-bottom: 20px;
	}

	.link-footer {
		font-size: 14px;
		margin-bottom: 11px;
	}

	.copyright {
		padding: 25px 0;
	}

	.text-copyright {
		font-size: 14px;
		margin-bottom: 0;
	}

	.col-copyright-left {
		text-align: center;
	}

	.col-copyright-right {
		text-align: center;
	}
	.align-container {
		text-align: center;
	}
	.icon-footer {
		height: 28px;
		width: 100%;
		margin-right: 0;
		display: flex;
		margin: 0 auto;
		margin-bottom: 10px;
	}
}

