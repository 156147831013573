.footer {
	background-color: #452b1e;
	/* background-color: #372e26; */
	padding: 120px 0;
}
.logo-footer {
	height: 100px;
}

.title-footer {
	font-size: 20px;
	color: #fff;
	font-weight: 400;
	text-transform: uppercase;
	margin-bottom: 30px;
	letter-spacing: 1.1px;
}

.link-footer {
	font-size: 15px;
	color: #a38677;
	font-weight: 400;
	margin-bottom: 15px;
	transition: all .3s;
	cursor: pointer;
}

.link-footer:hover {
	color: #ddbbaa;
}

.copyright {
	padding: 20px 0;
	background-color: #24170f;
}

.text-copyright {
	font-size: 14px;
	color: #a38677;
	font-weight: 400;
	transition: all .3s;
}

.made-by-copyright {
	color: #ddbbaa;
}

.col-copyright-left {
	text-align: left;
}

.col-copyright-right {
	text-align: right;
}
.icon-footer {
	height: 28px;
	margin-right: 10px;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
	.footer {
		padding: 60px 0;
	}

	.footer-group {
		margin-bottom: 45px;
	}

	.logo-footer {
		height: 80px;
		margin-bottom: 50px;
	}

	.title-footer {
		font-size: 20px;
		margin-bottom: 20px;
	}

	.link-footer {
		font-size: 14px;
		margin-bottom: 11px;
	}

	.copyright {
		padding: 25px 0;
	}

	.text-copyright {
		font-size: 14px;
		margin-bottom: 0;
	}

	.col-copyright-left {
		text-align: center;
	}

	.col-copyright-right {
		text-align: center;
	}
	.align-container {
		text-align: center;
	}
	.icon-footer {
		height: 28px;
		width: 100%;
		margin-right: 0;
		display: flex;
		margin: 0 auto;
		margin-bottom: 10px;
	}
}
