@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');

body {
	font-family: 'Roboto', sans-serif;
}
.reset-link-style {
	color: inherit;
	text-decoration: inherit;
}

.reset-link-style:hover,
.reset-link-style:focus,
.reset-link-style:active {
	color: inherit;
	text-decoration: inherit;
}

img {
	user-select: none;
}

.modal-content {
	border: none;
	border-radius: 0;
	background-color: #f0ede8;
}

.modal-body {
	position: relative;
	flex: 1 1 auto;
	padding: 30px;
}

@media screen and (min-width: 0px) and (max-width: 992px) {
	.modal-body {
		padding: 30px 15px;
	}
}
